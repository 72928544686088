<template>
  <div class="body">
    <!-- 抗原缴费 -->
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <van-tabs
      v-model="activeName"
      title-active-color="#00D48B "
      color="#00D48B"
      swipeable
      @change="handleTabChange"
    >
      <van-tab title="未缴费" name="a">
        <!-- 支付列表 -->
        <div
          class="content-list"
          v-for="(item, index) in nopaylist"
          :key="index"
        >
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">执行科室：</span
            >{{ item.dept_name }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">就诊时间：</span
            >{{ item.order_date }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">费用：</span
            ><span style="color: #ee0a24">￥{{ item.amount }}</span>
          </div>
          <div class="van-hairline--bottom"></div>
          <div class="notice-tips">
            <span class="notice-text">就诊时间当天有效，否则被自动取消</span>
            <!-- <span class="gotoCancle" @click="gotoCancle(item)">取消</span> -->
            <span class="gotoPay" @click="gotoPay(item)">去支付</span>
            <!-- <span
                class="gotoPay"
                @click="gotoPayDetails(item)"
                style="margin-right: 5px"
                >详情</span
              > -->
          </div>
        </div>
        <div class="baogao3" v-if="nopaylist.length == 0">
          <img src="../../assets/img/ucenter/21.png" alt="" />
        </div>
      </van-tab>
      <van-tab title="已缴费" name="b">
        <!-- 支付列表 -->
        <div class="content-list" v-for="(item, index) in paylist" :key="index">
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">执行科室：</span
            >{{ item.dept_name }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">就诊时间：</span
            >{{ item.order_date }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">费用：</span
            ><span style="color: #ee0a24">￥{{ item.amount }}</span>
          </div>
          <div class="van-hairline--bottom"></div>
          <div class="order-btn notice-tips">
            <span class="gotoCancle">{{
              changeStatus[item.changeStatus]
            }}</span>
            <div>
              <van-button
                plain
                size="small"
                type="primary"
                @click="gotoDetail(item)"
                >费用详情</van-button
              >
              <van-button
                plain
                size="small"
                type="danger"
                v-if="item.allowRefundStatus == 1"
                @click="handleRefund(item)"
                >退款
              </van-button>
            </div>
            <!-- <van-button plain size="small" type="danger">退款</van-button> -->
          </div>
        </div>
        <div class="baogao3" v-if="paylist.length == 0">
          <img src="../../assets/img/ucenter/21.png" alt="" />
        </div>
      </van-tab>
    </van-tabs>
    <van-dialog
      class="dialog"
      v-model="overflowShow"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">提示</h3>
      </template>
      <p>是否申请退费？</p>
      <van-button class="cancle-btn" @click="overflowShow = false"
        >取消</van-button
      >
      <van-button
        type="primary"
        class="confirm-btn"
        @click="gotoCancle(cancleItem, status)"
        >确认</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "outpatientPayment",
  data() {
    return {
      showPopup: false, // 弹出层
      list: [],
      personId: "",
      chooseItem: "",
      paylist: [],
      nopaylist: [],
      activeName: "",
      overflowShow: false,
      cancleItem: "",
      changeStatus: {
        0: "未支付",
        1: "已支付",
        2: "待审核",
        3: "退款中",
        4: "已关闭",
        5: "已取消",
        6: "已退款",
        7: "拒绝退款",
        8: "支付中",
        9: "异常",
      },
    };
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.showPopup = true;
        return;
      }
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    chooseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.getDataList1();
      this.getDataList2();
    },
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                sessionStorage.setItem(
                  "CurrentJZPerson",
                  JSON.stringify(this.chooseItem)
                );
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            this.getDataList1();
            this.getDataList2();
            this.isHasPatient();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    // 未支付
    getDataList1() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
      });
      let postData = {};
      postData.healthId = this.chooseItem.healthId;
      // postData.patid = this.chooseItem.patId;
      // postData.patid = "0010406593";
      postData.status = false;
      this.$http
        .get("/api/antigen/paymentList", postData)
        .then((result) => {
          this.nopaylist = result.data;
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 已支付
    getDataList2() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
      });
      let postData = {};
      postData.healthId = this.chooseItem.healthId;
      // postData.patid = this.chooseItem.patId;
      postData.status = true;
      this.$http
        .get("/api/antigen/paymentList", postData)
        .then((result) => {
          this.paylist = result.data;
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    formatDate() {
      var date = new Date();

      var year = date.getFullYear(),
        month = date.getMonth() + 1, //月份是从0开始的
        day = date.getDate();
      var newTime = year + "-" + month + "-" + day;
      return newTime;
    },
    // 去支付
    gotoPay(item) {
      this.$router.push({
        path: "/antigenDectectionWxPayPage",
        query: {
          id: item.order_id,
          price: item.amount * 100,
          collectType: 0,
          type: 1,
        },
      });
    },
    gotoDetail(item) {
      this.$router.push({
        path: "/antigenDectectionRecordDtail",
        query: {
          id: item.order_id,
        },
      });
    },
    // 查看门诊记录未缴费的缴费条目
    gotoPayDetails(item) {
      this.$router.push({
        path: "/paymentListEntry",
        query: {
          clinicSeq: item.clinicSeq,
          patid: this.chooseItem.patId,
        },
      });
    },
    handleTabChange(name, title) {
      this.$router.replace({
        query: {
          activeName: name,
        },
      });
    },
    handleRefund(item) {
      this.overflowShow = true;
      this.cancleItem = item;
    },
    gotoCancle(item) {
      let param = {
        id: item.bizOrderLineNo,
      };
      this.$http
        .get("/api/antigen/refund", param)
        .then((result) => {
          Toast({ message: "申请退费成功" });
          this.getDataList2();
        })
        .catch(() => {
          Toast.fail({
            message: "申请退费失败",
          });
          this.getDataList2();
        });
    },
  },
  mounted() {
    this.getList();
    this.activeName = this.$route.query.activeName;
  },
};
</script>

<style scoped>
.body {
  background-color: #f8f9f9;
}
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -1px;
  left: -2px;
}
.content-list {
  width: 9rem;
  margin: 10px auto;
  text-align: left;
  padding: 5px;
  padding-bottom: 2px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.baseinfo-item {
  font-size: 0.4rem;
  line-height: 26px;
}

.baseinfo-item-label {
  width: 2.5rem;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
}
.notice-tips {
  padding: 5px;
}
.notice-tips .notice-text {
  font-size: 0.3rem;
  color: #1d9ecf;
  display: inline-block;
  text-overflow: ellipsis;
  width: 4.7rem;
  overflow: hidden;
  white-space: nowrap;
}
.notice-tips .gotoCancle {
  font-size: 0.35rem;
  color: #333333;
  float: right;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.notice-tips .gotoPay {
  font-size: 0.35rem;
  float: right;
  color: #03d28c;
}
.notice-tips .cancled {
  display: inline-block;
  float: right;
}
.notice-detail {
  font-size: 0.35rem;
  color: #03d28c;
  display: inline-block;
}
.order-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-btn .van-button {
  border-radius: 5px;
  margin-left: 0.2rem;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}

/deep/ .van-tab--active {
  background-color: #f5f5f5;
}
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}
</style>
